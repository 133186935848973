@import url(https://fonts.googleapis.com/css?family=Bree+Serif&display=swap);
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Bree Serif", serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-repeat: no-repeat;
    background-position: top center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media screen and (max-width: 600px) {
    body {
        /* background-size: auto 50em; */
        background: none;
    }
}

@media screen and (max-width: 400px) {
    body {
        /* background-size: auto 30em; */
    }
}

.desktopMenu .eu-logo {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.desktopMenu .eu-logo img {
  max-width: 270px;
  height: auto;
}
.desktopMenu .menubar {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #151515;
  font-size: 20px;
  height: 4.5em;
  justify-content: space-between;
  overflow: hidden;
}
.desktopMenu .menubar__order-button {
  background-color: orange;
  color: black;
  padding: 10px 20px;
  border-radius: 40px 40px 40px 40px;
  -moz-border-radius: 40px 40px 40px 40px;
  -webkit-border-radius: 40px 40px 40px 40px;
  border: 0px solid #000000;
}
.desktopMenu .menubar__order-button:hover {
  background-color: #ffae1a;
}
.desktopMenu .menubar__logo {
  margin: 0 1.5rem 0 3rem;
  height: 100%;
  display: flex;
  align-items: center;
}
.desktopMenu .menubar__logo img {
  max-width: 100%;
  height: auto;
  cursor: pointer;
  margin-top: 0;
}
.desktopMenu .menubar .sticky-logo {
  margin: 0 30px 0 140px;
}
@media screen and (max-width: 1515px) {
  .desktopMenu .menubar .sticky-logo {
    margin: 0 30px 0 145px;
  }
}
@media screen and (max-width: 1420px) {
  .desktopMenu .menubar .sticky-logo {
    margin: 0 30px 0 20px;
  }
}
.desktopMenu .menubar__item {
  padding: 0 10px 0 10px;
  font-size: 1rem;
}
.desktopMenu .menubar__item p {
  margin: 0;
}
.desktopMenu .menubar__item p a {
  text-transform: uppercase;
}
.desktopMenu .menubar__item a {
  -webkit-transition: color 0.3s linear;
  transition: color 0.3s linear;
}
.desktopMenu .menubar__item a:active,
.desktopMenu .menubar__item a:visited,
.desktopMenu .menubar__item a:link {
  color: white;
  text-decoration: none;
}
.desktopMenu .menubar__item a:hover {
  color: orange;
}
.desktopMenu .menubar__order {
  margin: 0 30px 0 30px;
}
.desktopMenu .menubar__order .order-button {
  color: #28a745;
  border: 2px solid #28a745;
  padding: 5px 35px 5px 35px;
  border-radius: 25px 25px 25px 25px;
}
.desktopMenu .menubar__order .order-button:hover {
  cursor: pointer;
}
.desktopMenu .menubar__langSel {
  margin: 10px 30px 0 0;
  width: 100px;
  color: #bbb;
}
.desktopMenu .menubar__langSel span {
  cursor: pointer;
}
.desktopMenu .menubar__toggleButton {
  display: none;
}
.desktopMenu .menubar__toggleButton:hover {
  cursor: pointer;
}
.desktopMenu .menubar__toggleButton i:before {
  font-size: 35px;
}
.desktopMenu .sticky {
  width: 100%;
}
@media screen and (max-width: 1425px) {
  .desktopMenu .menubar {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .desktopMenu {
    display: none;
  }
}

/*
  	Flaticon icon font: Flaticon
  	Creation date: 15/08/2019 12:26
  	*/

@font-face {
    font-family: "Flaticon";
    src: url(/static/media/Flaticon.a5e45b73.eot);
    src: url(/static/media/Flaticon.a5e45b73.eot?#iefix) format("embedded-opentype"),
        url(/static/media/Flaticon.fff989ff.woff2) format("woff2"), url(/static/media/Flaticon.c2f6a7fb.woff) format("woff"),
        url(/static/media/Flaticon.7e21b0ca.ttf) format("truetype"), url(/static/media/Flaticon.d1a46a11.svg#Flaticon) format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "Flaticon";
        src: url(/static/media/Flaticon.d1a46a11.svg#Flaticon) format("svg");
    }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: 20px;
    font-style: normal;
    margin-left: 20px;
}

.flaticon-menu:before {
    content: "\f100";
}
.flaticon-information:before {
    content: "\f101";
}
.flaticon-twitter-logo-silhouette:before {
    content: "\f102";
}
.flaticon-instagram:before {
    content: "\f103";
}
.flaticon-facebook-logo:before {
    content: "\f104";
}
.flaticon-copyright:before {
    content: "\f105";
}

.mobileMenu {
  width: 100%;
  margin: 0 auto;
}
.mobileMenu .eu-logo {
  position: fixed;
  right: 0;
  top: 3.5rem;
  z-index: -1;
}
.mobileMenu .eu-logo img {
  max-width: 270px;
  height: auto;
}
.mobileMenu .mobileMenu__header {
  background-color: #151515;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  height: 5em;
}
.mobileMenu .mobileMenu__header .header-logo {
  display: flex;
  align-items: center;
}
.mobileMenu .mobileMenu__header .header-logo img {
  max-width: 7rem;
  height: auto;
}
.mobileMenu .mobileMenu__header .header__toggleButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 2rem;
}
.mobileMenu .mobileMenu__header .header__toggleButton:hover {
  cursor: pointer;
}
.mobileMenu .mobileMenu__header .header__toggleButton i:before {
  font-size: 35px;
  margin-left: 0;
}
@media screen and (max-width: 400px) {
  .mobileMenu .mobileMenu__header {
    height: 3.5rem;
  }
  .mobileMenu .mobileMenu__header .header-logo {
    display: flex;
    align-items: center;
  }
  .mobileMenu .mobileMenu__header .header-logo img {
    cursor: pointer;
    max-width: 5rem;
    height: auto;
  }
  .mobileMenu .mobileMenu__header .header__toggleButton i:before {
    font-size: 20px;
  }
}
.mobileMenu .mobile-menubar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  background-color: #151515;
  color: #bbb;
  font-size: 20px;
}
.mobileMenu .mobile-menubar .mobile-menubar__item {
  margin: 10px 30px 0 30px;
}
.mobileMenu .mobile-menubar .mobile-menubar__item p a {
  text-transform: uppercase;
}
.mobileMenu .mobile-menubar .mobile-menubar__item a:active,
.mobileMenu .mobile-menubar .mobile-menubar__item a:hover,
.mobileMenu .mobile-menubar .mobile-menubar__item a:visited,
.mobileMenu .mobile-menubar .mobile-menubar__item a:link {
  color: white;
  text-decoration: none;
}
.mobileMenu .mobile-menubar .mobile-menubar-order-button {
  margin-top: 0;
  padding-bottom: 10px;
}
.mobileMenu .mobile-menubar .mobile-menubar-order-button .menubar__order-button {
  background-color: orange;
  color: black;
  padding: 10px 20px;
  border-radius: 40px 40px 40px 40px;
  -moz-border-radius: 40px 40px 40px 40px;
  -webkit-border-radius: 40px 40px 40px 40px;
  border: 0px solid #000000;
}
.mobileMenu .mobile-menubar .mobile-menubar-order-button .menubar__order-button:hover {
  background-color: #ffae1a;
}
.mobileMenu .mobile-menubar .mobile-menubar__order {
  margin: 10px 30px 0 30px;
}
.mobileMenu .mobile-menubar .mobile-menubar__order .order-button {
  color: #28a745;
  border: 2px solid #28a745;
  padding: 5px 35px 5px 35px;
  border-radius: 25px 25px 25px 25px;
}
.mobileMenu .mobile-menubar .mobile-menubar__order .order-button:hover {
  cursor: pointer;
}
.mobileMenu .mobile-menubar .mobile-menubar__langSel {
  margin: 10px 30px 10px 30px;
}
.mobileMenu .mobile-menubar .mobile-menubar__langSel span {
  cursor: pointer;
}
@media screen and (min-width: 1201px) {
  .mobileMenu {
    display: none;
  }
}
.sticky {
  width: 100%;
}

.homePage {
  position: relative;
  background-image: url(/static/media/banner.87bfb852.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 60px;
  background-color: #151515 1;
}
.homePage .above {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
@media screen and (max-width: 1199px) {
  .homePage {
    background-image: url(/static/media/banner_small.7fe8d0d3.jpg);
    background-position: center 60px;
    padding-top: 80px;
  }
}
@media screen and (max-width: 1421px) {
  .homePage {
    padding-top: 0;
  }
}
@media screen and (max-width: 400px) {
  .homePage {
    background-position: center 50px;
  }
}
.homePage .under {
  padding-top: 30em;
}
@media screen and (max-width: 1199px) {
  .homePage .under {
    padding-top: 20em;
  }
}
.no-bg-wrapper {
  background-image: none !important;
}

.about-container {
  padding-top: 10em;
  padding-bottom: 10em;
  background-color: #151515;
  color: white;
}
.about-container .about-us {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 90rem;
  margin: 0 auto;
}
.about-container .about-us__image-container {
  margin-top: -20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-container .about-us__image-container .aboutImg {
  padding: 0 1rem;
}
.about-container .about-us__image-container .aboutImg:last-of-type {
  text-align: left;
}
.about-container .about-us__image-container .aboutImg:first-of-type {
  text-align: right;
}
.about-container .about-us__image-container .aboutImg img {
  max-width: 70%;
  height: auto;
  border: 0.5rem solid white;
  border-radius: 50%;
}
.about-container .about-us__title {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 2rem;
}
.about-container .about-us__text {
  text-align: justify;
  max-width: 50rem;
  font-size: 1.2rem;
  margin: 0 auto;
}
@media screen and (max-width: 850px) {
  .about-container .about-us__image-container {
    margin-top: -15rem;
  }
}
@media screen and (max-width: 576px) {
  .about-container .about-us__image-container {
    margin-top: -15rem;
    padding-bottom: 4rem;
  }
  .about-container .about-us__image-container .aboutImg img {
    max-width: 90%;
  }
}
@media screen and (max-width: 400px) {
  .about-container .about-us__image-container {
    flex-direction: column;
    padding-bottom: 0;
  }
  .about-container .about-us__image-container .aboutImg {
    max-width: 80%;
    margin: 0 auto;
  }
  .about-container .about-us__image-container .aboutImg:first-of-type {
    text-align: center;
    padding-bottom: 1.5rem;
  }
  .about-container .about-us__image-container .aboutImg:last-of-type {
    text-align: center;
  }
}
@media screen and (max-width: 1200px) {
  .about-container {
    padding-bottom: 3rem;
  }
}
.kitchen-section {
  width: 100%;
  background-image: url(/static/media/pattern.b921857e.jpg);
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.kitchen-section .kitchen-image {
  max-width: 100%;
  height: auto;
}
.kitchen-section .kitchen-text {
  width: 100%;
  height: 100%;
  background-color: #151515;
  color: white;
  padding: 1rem 1.5rem;
  text-align: justify;
}
.kitchen-section .kitchen-text .kitchen-title {
  font-size: 1.6rem;
  text-align: center;
  padding-bottom: 1rem;
}
.kitchen-section .kitchen {
  padding-bottom: 4rem;
}
.mozaik-image {
  width: 20%;
  height: 350px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: inline-block;
}
@media (max-width: 900px) {
  .mozaik-image {
    width: 50%;
  }
}

.weeklyMenu {
  background-color: #151515;
  color: #cfcfcf;
  padding-top: 5em;
  padding-bottom: 10em;
  background-size: 20%;
}
.weeklyMenu .menu .menu__box {
  display: flex;
  flex-direction: column;
}
.weeklyMenu .menu .menu__box .menu__box__banner {
  color: orange;
  width: 100%;
  padding: 10px 0px 0px 40px;
  border-bottom: 5px dotted grey;
}
.weeklyMenu .menu .menu__box .menu__box__banner .menu__price {
  font-size: 25px;
  color: white;
}
.weeklyMenu .menu .menu__box .menu__box__banner .menu__title {
  font-size: 35px;
}
@media screen and (max-width: 620px) {
  .weeklyMenu .menu .menu__box .menu__box__banner .menu__title {
    font-size: 25px;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList {
  display: flex;
}
@media only screen and (max-width: 1200px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList {
    flex-direction: column;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  flex: 0 0 20%;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__Date {
  color: white;
  background-color: #2c2c2c;
  padding-top: 10px;
  min-width: 10em;
  text-align: center;
  margin: 0 0.5rem;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__Date .Date__day {
  font-size: 25px;
}
@media screen and (max-width: 500px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__Date {
    height: 5em;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
  font-size: 20px;
  margin-left: 30px;
}
@media screen and (max-width: 768px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
    padding: 0 10px;
  }
}
@media screen and (max-width: 500px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
    font-size: 15px;
    margin-left: 10px;
  }
}
@media screen and (max-width: 430px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
    font-size: 20px;
    margin-left: 0;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu .option {
  margin-bottom: 1rem;
  font-size: 16px;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu .option--ab {
  position: relative;
  padding-left: 2rem;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu--fixed {
  border-bottom: 2px dotted #525252;
  padding-top: 1rem;
  min-height: 250px;
}
@media only screen and (max-width: 1200px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu--fixed {
    min-height: unset;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu--fixed .optiontype {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0 0.5rem;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  background-color: white;
  color: black;
  position: absolute;
  left: 0;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu .special-offer {
  margin-bottom: 1rem;
  border-bottom: 1px solid #8f8f8f;
  min-height: 72px;
}
@media only screen and (max-width: 1200px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu .special-offer {
    min-height: unset;
    text-align: center;
  }
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu .special-offer h3 {
    font-size: 20px;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu--optional {
  padding-top: 1rem;
  padding-bottom: 3rem;
}
@media screen and (max-width: 430px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList {
    flex-direction: column;
  }
}
@media screen and (max-width: 1199px) {
  .weeklyMenu .menu .menu__box {
    margin: 0 auto;
    padding-left: 0;
    margin-top: 4rem;
  }
}
.weeklyMenu .menu .image-container {
  width: 100%;
}
.weeklyMenu .menu .image-container .image {
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 576px) {
  .weeklyMenu {
    padding-bottom: 3rem;
  }
}

.reservation {
  background-image: url(/static/media/reservation.2b7091fa.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 6em;
  position: relative;
}
.reservation .reservation-gradient {
  height: 100%;
  width: 100%;
  background-image: -webkit-gradient(270deg, transparent 0%, #00000088 50%, #000000cc 100%);
  background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, #00000088), to(#000000cc));
  background-image: linear-gradient(270deg, transparent 0%, #00000088 50%, #000000cc 100%);
  position: absolute;
}
.reservation .reservation-box {
  display: flex;
  background-color: #151515;
  padding-bottom: 2rem;
  max-width: 30rem;
  margin: 6rem auto 0 auto;
  justify-content: center;
}
.reservation .reservation-box .reservation-box__content {
  width: 100%;
}
.reservation .reservation-box .reservation-box__content .reservation-box__header {
  background-color: #28a745;
  padding: 0.5rem 1.5rem;
  text-align: center;
  color: white;
  margin-bottom: 3rem;
}
.reservation .reservation-box .reservation-box__content .reservation-box__header .header__banner {
  font-size: 35px;
}
@media screen and (max-width: 620px) {
  .reservation .reservation-box .reservation-box__content .reservation-box__header .header__banner {
    font-size: 25px;
  }
}
.reservation .reservation-box .reservation-box__content .reservation-box__header .header__detail {
  font-size: 14px;
}
@media screen and (max-width: 400px) {
  .reservation .reservation-box .reservation-box__content .reservation-box__header {
    width: 100%;
  }
}
.reservation .reservation-box .reservation-box__content .reservation-box__form {
  width: 90%;
  margin: 0 auto;
  color: white;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form input {
  border-radius: 0;
  padding: 1.5rem 1rem;
  background-color: #444;
  border: 0.1rem solid #555;
  color: white;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border-radius: 3rem;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form input::-webkit-input-placeholder {
  color: hsl(0, 0%, 50%);
}
.reservation .reservation-box .reservation-box__content .reservation-box__form input::-moz-placeholder {
  color: hsl(0, 0%, 50%);
}
.reservation .reservation-box .reservation-box__content .reservation-box__form input:-ms-input-placeholder {
  color: hsl(0, 0%, 50%);
}
.reservation .reservation-box .reservation-box__content .reservation-box__form input::-ms-input-placeholder {
  color: hsl(0, 0%, 50%);
}
.reservation .reservation-box .reservation-box__content .reservation-box__form input::placeholder {
  color: hsl(0, 0%, 50%);
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .fourthLine .opening-information {
  font-size: 14px;
  padding-top: 30px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box {
  text-align: right;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .submitButton {
  color: #151515;
  font-size: 1.2rem;
  border-radius: 3rem;
  padding: 0.7rem 1.5rem;
  border: 0;
  background-color: orange;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  outline: none !important;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .submitButton:hover {
  background-color: #cc8400;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo .moreInfo__icon {
  margin-right: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo .moreInfo__text {
  font-size: 13px;
  margin-top: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo:hover {
  cursor: pointer;
}
@media screen and (max-width: 410px) {
  .reservation .reservation-box .reservation-box__content {
    width: 100%;
  }
}

.footer {
    background-color: #151515;
    color: white;
    padding-top: 3em;
}
.footer .footer__logo {
    display: flex;
    justify-content: flex-end;
}
@media screen and (max-width: 1199px) {
    .footer .footer__logo {
        justify-content: center;
    }
}
.footer .footer__middleContent .footer__middleContent__above {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__title {
    font-size: 20px;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__detailIcons {
    display: inline;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__detailIcons a {
    color: white;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__detailIcons a:hover {
    color: #bbb;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__detailIcons i {
    margin-right: 20px;
}
@media screen and (max-width: 600px) {
    .footer .footer__middleContent .footer__middleContent__above {
        flex-direction: column;
    }
}
.footer .footer__middleContent .footer__middleContent__under {
    margin-top: 30px;
}
.footer
    .footer__middleContent
    .footer__middleContent__under
    .footer__middleContent__under__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.footer
    .footer__middleContent
    .footer__middleContent__under
    .footer__middleContent__under__content
    .copyright-icon {
    margin-right: 5px;
}
.footer
    .footer__middleContent
    .footer__middleContent__under
    .footer__middleContent__under__content
    .copyright-icon
    i:before {
    font-size: 12px;
}
.footer
    .footer__middleContent
    .footer__middleContent__under
    .footer__middleContent__under__content
    .copyright-text {
    margin-top: 2px;
    margin-right: 25px;
    font-size: 14px;
}

.locations-page {
  width: 100%;
  background-image: url(/static/media/pattern.b921857e.jpg);
  margin-top: -10rem;
}
@media screen and (max-width: 1199px) {
  .locations-page {
    padding-top: 60px;
  }
}
.locations-page .card-container {
  padding: 2rem;
  width: 50%;
}
.locations-page .locations {
  padding-top: 15rem;
  padding-bottom: 4rem;
  display: flex;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  max-width: 1800px;
  color: white;
  flex-wrap: wrap;
}
.locations-page .locations__card {
  width: 100%;
  background-color: #151515;
}
.locations-page .locations__card--image {
  border: 0.2rem solid white;
  width: 100%;
  height: auto;
}
.locations-page .locations__card--image img {
  max-width: 100%;
  height: auto;
}
.locations-page .locations__card--text {
  padding: 1rem 1.5rem;
  font-size: 3rem;
  text-align: center;
}
.locations-page .locations__card--description {
  font-size: 1.4rem;
  text-align: justify;
  padding: 0rem 2.5rem 0 2.5rem;
  min-height: 14rem;
}
@media screen and (max-width: 1250px) {
  .locations-page .locations__card--text {
    font-size: 2rem;
  }
  .locations-page .locations__card--description {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 991px) {
  .locations-page .locations__card--description {
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .locations-page .locations .card-container {
    width: 100%;
  }
  .locations-page .locations__card--description {
    min-height: 10rem;
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .locations-page .locations .card-container {
    padding: 1rem 0;
  }
}

.menu-wrapper {
  color: white;
  background-image: url(/static/media/pattern.b921857e.jpg);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem;
}
.menu-wrapper h1 {
  font-size: 3rem;
}
.menu-wrapper .menu-content {
  width: 800px;
}
@media only screen and (max-width: 1200px) {
  .menu-wrapper .menu-content {
    width: 300px;
  }
}
.menu-wrapper .menu-content h2 {
  text-align: left;
  width: 100%;
  color: orange;
  border-bottom: 5px dotted grey;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
}
.menu-wrapper .menu-content h3 {
  padding: 1rem 0;
  margin-bottom: 1rem;
  border-bottom: 2px dotted #525252;
}
.menu-wrapper .menu-item {
  display: flex;
  width: 100%;
  padding-bottom: 1rem;
}
.menu-wrapper .menu-item__left,
.menu-wrapper .menu-item__right {
  flex: 0 0 50%;
}
.menu-wrapper .menu-item__left {
  text-align: left;
}
.menu-wrapper .menu-item__left p {
  color: #a0a0a0;
  font-size: 14px;
}
.menu-wrapper .menu-item__right {
  text-align: right;
  font-size: 1.5rem;
}
.menu-wrapper .spinner-wrapper {
  margin: 2rem auto;
  width: 4rem;
  text-align: center;
}

.drink-menu-wrapper {
  color: white;
  background-image: url(/static/media/pattern.b921857e.jpg);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem;
}
.drink-menu-wrapper h1 {
  font-size: 3rem;
}
.drink-menu-wrapper .menu-content {
  width: 800px;
}
@media only screen and (max-width: 992px) {
  .drink-menu-wrapper .menu-content {
    width: 100%;
    flex: 0 0 100%;
  }
}
.drink-menu-wrapper .menu-content h2 {
  text-align: left;
  width: 100%;
  color: orange;
  border-bottom: 5px dotted grey;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
}
.drink-menu-wrapper .menu-content h3 {
  padding: 1rem 0;
  margin-bottom: 1rem;
  border-bottom: 2px dotted #525252;
}
.drink-menu-wrapper .menu-item {
  display: flex;
  width: 100%;
  padding-bottom: 1rem;
}
@media only screen and (max-width: 992px) {
  .drink-menu-wrapper .menu-item {
    flex-direction: column;
  }
}
.drink-menu-wrapper .menu-item__left,
.drink-menu-wrapper .menu-item__right {
  flex: 0 0 50%;
}
@media only screen and (max-width: 992px) {
  .drink-menu-wrapper .menu-item__left,
  .drink-menu-wrapper .menu-item__right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.drink-menu-wrapper .menu-item__left {
  text-align: left;
}
.drink-menu-wrapper .menu-item__left p {
  color: #a0a0a0;
  font-size: 14px;
  margin-bottom: 0;
}
.drink-menu-wrapper .menu-item__right {
  text-align: right;
  font-size: 1rem;
  display: flex;
}
.drink-menu-wrapper .menu-item__right .price {
  width: 20%;
  font-weight: 400;
}
.drink-menu-wrapper .spinner-wrapper {
  margin: 2rem auto;
  width: 4rem;
  text-align: center;
}

