.weeklyMenu {
  background-color: #151515;
  color: #cfcfcf;
  padding-top: 5em;
  padding-bottom: 10em;
  background-size: 20%;
}
.weeklyMenu .menu .menu__box {
  display: flex;
  flex-direction: column;
}
.weeklyMenu .menu .menu__box .menu__box__banner {
  color: orange;
  width: 100%;
  padding: 10px 0px 0px 40px;
  border-bottom: 5px dotted grey;
}
.weeklyMenu .menu .menu__box .menu__box__banner .menu__price {
  font-size: 25px;
  color: white;
}
.weeklyMenu .menu .menu__box .menu__box__banner .menu__title {
  font-size: 35px;
}
@media screen and (max-width: 620px) {
  .weeklyMenu .menu .menu__box .menu__box__banner .menu__title {
    font-size: 25px;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList {
  display: flex;
}
@media only screen and (max-width: 1200px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList {
    flex-direction: column;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  flex: 0 0 20%;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__Date {
  color: white;
  background-color: #2c2c2c;
  padding-top: 10px;
  min-width: 10em;
  text-align: center;
  margin: 0 0.5rem;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__Date .Date__day {
  font-size: 25px;
}
@media screen and (max-width: 500px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__Date {
    height: 5em;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
  font-size: 20px;
  margin-left: 30px;
}
@media screen and (max-width: 768px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
    padding: 0 10px;
  }
}
@media screen and (max-width: 500px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
    font-size: 15px;
    margin-left: 10px;
  }
}
@media screen and (max-width: 430px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
    font-size: 20px;
    margin-left: 0;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu .option {
  margin-bottom: 1rem;
  font-size: 16px;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu .option--ab {
  position: relative;
  padding-left: 2rem;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu--fixed {
  border-bottom: 2px dotted #525252;
  padding-top: 1rem;
  min-height: 250px;
}
@media only screen and (max-width: 1200px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu--fixed {
    min-height: unset;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu--fixed .optiontype {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0 0.5rem;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  background-color: white;
  color: black;
  position: absolute;
  left: 0;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu .special-offer {
  margin-bottom: 1rem;
  border-bottom: 1px solid #8f8f8f;
  min-height: 72px;
}
@media only screen and (max-width: 1200px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu .special-offer {
    min-height: unset;
    text-align: center;
  }
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu .special-offer h3 {
    font-size: 20px;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu--optional {
  padding-top: 1rem;
  padding-bottom: 3rem;
}
@media screen and (max-width: 430px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList {
    flex-direction: column;
  }
}
@media screen and (max-width: 1199px) {
  .weeklyMenu .menu .menu__box {
    margin: 0 auto;
    padding-left: 0;
    margin-top: 4rem;
  }
}
.weeklyMenu .menu .image-container {
  width: 100%;
}
.weeklyMenu .menu .image-container .image {
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 576px) {
  .weeklyMenu {
    padding-bottom: 3rem;
  }
}
