.about-container {
  padding-top: 10em;
  padding-bottom: 10em;
  background-color: #151515;
  color: white;
}
.about-container .about-us {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 90rem;
  margin: 0 auto;
}
.about-container .about-us__image-container {
  margin-top: -20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-container .about-us__image-container .aboutImg {
  padding: 0 1rem;
}
.about-container .about-us__image-container .aboutImg:last-of-type {
  text-align: left;
}
.about-container .about-us__image-container .aboutImg:first-of-type {
  text-align: right;
}
.about-container .about-us__image-container .aboutImg img {
  max-width: 70%;
  height: auto;
  border: 0.5rem solid white;
  border-radius: 50%;
}
.about-container .about-us__title {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 2rem;
}
.about-container .about-us__text {
  text-align: justify;
  max-width: 50rem;
  font-size: 1.2rem;
  margin: 0 auto;
}
@media screen and (max-width: 850px) {
  .about-container .about-us__image-container {
    margin-top: -15rem;
  }
}
@media screen and (max-width: 576px) {
  .about-container .about-us__image-container {
    margin-top: -15rem;
    padding-bottom: 4rem;
  }
  .about-container .about-us__image-container .aboutImg img {
    max-width: 90%;
  }
}
@media screen and (max-width: 400px) {
  .about-container .about-us__image-container {
    flex-direction: column;
    padding-bottom: 0;
  }
  .about-container .about-us__image-container .aboutImg {
    max-width: 80%;
    margin: 0 auto;
  }
  .about-container .about-us__image-container .aboutImg:first-of-type {
    text-align: center;
    padding-bottom: 1.5rem;
  }
  .about-container .about-us__image-container .aboutImg:last-of-type {
    text-align: center;
  }
}
@media screen and (max-width: 1200px) {
  .about-container {
    padding-bottom: 3rem;
  }
}
.kitchen-section {
  width: 100%;
  background-image: url("../../../../Assets/pattern.jpg");
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.kitchen-section .kitchen-image {
  max-width: 100%;
  height: auto;
}
.kitchen-section .kitchen-text {
  width: 100%;
  height: 100%;
  background-color: #151515;
  color: white;
  padding: 1rem 1.5rem;
  text-align: justify;
}
.kitchen-section .kitchen-text .kitchen-title {
  font-size: 1.6rem;
  text-align: center;
  padding-bottom: 1rem;
}
.kitchen-section .kitchen {
  padding-bottom: 4rem;
}
.mozaik-image {
  width: 20%;
  height: 350px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: inline-block;
}
@media (max-width: 900px) {
  .mozaik-image {
    width: 50%;
  }
}
