.desktopMenu .eu-logo {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.desktopMenu .eu-logo img {
  max-width: 270px;
  height: auto;
}
.desktopMenu .menubar {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #151515;
  font-size: 20px;
  height: 4.5em;
  justify-content: space-between;
  overflow: hidden;
}
.desktopMenu .menubar__order-button {
  background-color: orange;
  color: black;
  padding: 10px 20px;
  border-radius: 40px 40px 40px 40px;
  -moz-border-radius: 40px 40px 40px 40px;
  -webkit-border-radius: 40px 40px 40px 40px;
  border: 0px solid #000000;
}
.desktopMenu .menubar__order-button:hover {
  background-color: #ffae1a;
}
.desktopMenu .menubar__logo {
  margin: 0 1.5rem 0 3rem;
  height: 100%;
  display: flex;
  align-items: center;
}
.desktopMenu .menubar__logo img {
  max-width: 100%;
  height: auto;
  cursor: pointer;
  margin-top: 0;
}
.desktopMenu .menubar .sticky-logo {
  margin: 0 30px 0 140px;
}
@media screen and (max-width: 1515px) {
  .desktopMenu .menubar .sticky-logo {
    margin: 0 30px 0 145px;
  }
}
@media screen and (max-width: 1420px) {
  .desktopMenu .menubar .sticky-logo {
    margin: 0 30px 0 20px;
  }
}
.desktopMenu .menubar__item {
  padding: 0 10px 0 10px;
  font-size: 1rem;
}
.desktopMenu .menubar__item p {
  margin: 0;
}
.desktopMenu .menubar__item p a {
  text-transform: uppercase;
}
.desktopMenu .menubar__item a {
  transition: color 0.3s linear;
}
.desktopMenu .menubar__item a:active,
.desktopMenu .menubar__item a:visited,
.desktopMenu .menubar__item a:link {
  color: white;
  text-decoration: none;
}
.desktopMenu .menubar__item a:hover {
  color: orange;
}
.desktopMenu .menubar__order {
  margin: 0 30px 0 30px;
}
.desktopMenu .menubar__order .order-button {
  color: #28a745;
  border: 2px solid #28a745;
  padding: 5px 35px 5px 35px;
  border-radius: 25px 25px 25px 25px;
}
.desktopMenu .menubar__order .order-button:hover {
  cursor: pointer;
}
.desktopMenu .menubar__langSel {
  margin: 10px 30px 0 0;
  width: 100px;
  color: #bbb;
}
.desktopMenu .menubar__langSel span {
  cursor: pointer;
}
.desktopMenu .menubar__toggleButton {
  display: none;
}
.desktopMenu .menubar__toggleButton:hover {
  cursor: pointer;
}
.desktopMenu .menubar__toggleButton i:before {
  font-size: 35px;
}
.desktopMenu .sticky {
  width: 100%;
}
@media screen and (max-width: 1425px) {
  .desktopMenu .menubar {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .desktopMenu {
    display: none;
  }
}
